import { createSlice } from '@reduxjs/toolkit';
import { GroupResults, UserResults } from '../../components/types/Contacts';
import { RequestError } from '../Conversation/actions';
import { fetchTeams, fetchUsers } from './actions';

const initialState = {
  loading: false,
  teams: null as GroupResults | null,
  users: null as UserResults | null,
  error: null as any,
  modalType: null as any,
  openModal: false,
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    openContactModal(state, { payload }) {
      state.modalType = payload;
      state.openModal = true;
    },
    closeContactModal(state) {
      state.modalType = null;
      state.openModal = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchTeams.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.teams = payload as GroupResults;
      }
    });
    builder.addCase(fetchTeams.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching teams. Please try again'}; // TODO: add errors
    });
    builder.addCase(fetchUsers.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.users = payload as UserResults;
      }
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
  }
})
export const { openContactModal, closeContactModal } = contactsSlice.actions;
export default contactsSlice.reducer;