import { createSlice } from '@reduxjs/toolkit';
import { Messages } from '../../components/Dashboard/Conversations/types/Message';
import { RequestError } from '../Conversation/actions';
import { deleteMessage, fetchMessagesFromConversationId, sendMessage } from './actions';


const initialState = {
  loading: false,
  sending: false,
  messages: null as Messages | null,
  error: null as RequestError | null,
  sent: false,
  successfullyDeleted: false,
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    updateMessages(state, { payload }) {
      if (state.messages) {
        const messageThreads = state.messages.results;
        messageThreads.unshift(payload)
        state.messages = { ...state.messages, results: messageThreads }
      }
    },
    resetDeleteState(state) {
      state.successfullyDeleted = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchMessagesFromConversationId.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMessagesFromConversationId.fulfilled, (state, { meta, payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        if (state.messages && meta.arg.page !== 1) {
          const messagesResults = state.messages.results.concat((payload as Messages).results);
          state.messages = payload as Messages;
          state.messages.results = messagesResults;
        } else {
          state.messages = payload as Messages;
        }
      }
    });
    builder.addCase(fetchMessagesFromConversationId.rejected, (state) => {
      state.loading = false;
      // state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
    builder.addCase(sendMessage.pending, state => {
      state.sending = true;
      state.error = null;
    });
    builder.addCase(sendMessage.fulfilled, (state, { payload }) => {
      state.sending = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.sent = true;
      }
    });
    builder.addCase(sendMessage.rejected, (state) => {
      state.sending = false;
      // state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });

    builder.addCase(deleteMessage.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteMessage.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.successfullyDeleted = true;
      }
    });
    builder.addCase(deleteMessage.rejected, (state) => {
      state.loading = false;
      // state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
    
  }
})

export const { updateMessages, resetDeleteState } = messagesSlice.actions;

export default messagesSlice.reducer;
