import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { toast, ToastOptions } from 'react-toastify';
import axios from 'axios';
// import jwtDecode from 'jwt-decode';

const baseURL = process.env.GATSBY_API_URL || 'http://localhost:8000';

const MAX_RETRY_ATTEMPT = 5;

const AuthenticationState = {
  NoTokens: 'NoTokens',
  ExpiredRefreshToken: 'ExpiredRefreshToken',
  ExpiredAccessToken: 'ExpiredAccessToken',
  ValidTokens: 'ValidTokens',
  InvalidTokens: 'InvalidTokens',
};

const getAuthenticationStatus = () => {
  if (!Cookies.get('access') || !Cookies.get('refresh')) {
    return AuthenticationState.NoTokens;
  }

  const token = Cookies.get('access');
  const refresh = Cookies.get('refresh');
  let decodedToken: { [key: string]: any };
  let decodedRefresh: { [key: string]: any };

  try {
    decodedToken = jwtDecode(token as string);
    decodedRefresh = jwtDecode(refresh as string);
  } catch (e) {
    return AuthenticationState.InvalidTokens;
  }

  if (Date.now() / 1000 > decodedToken.exp) {
    if (Date.now() / 1000 > decodedRefresh.exp) {
      // Token Expired.
      return AuthenticationState.ExpiredRefreshToken;
    }
    // Token can be regenerated so therefore user is still authenticated
    return AuthenticationState.ExpiredAccessToken;
  }
  // Token has not expired and is still valid
  return AuthenticationState.ValidTokens;
};

const RefreshState = {
  SuccessfullyRefreshedTokens: 'SuccessfullyRefreshedTokens',
  ErrorRefreshingTokens: 'ErrorRefreshingTokens',
};

const refreshTokens = async () => {
  const refresh = Cookies.get('refresh');
  try {
    const response = await axios.post(`${baseURL}/contacts/token/refresh/`, {
      refresh,
    });
    const newAccess = response.data.access;
    const newRefresh = response.data.refresh;
    Cookies.set('access', newAccess, { expires: 30, sameSite: 'None', secure: true });
    Cookies.set('refresh', newRefresh, { expires: 90, sameSite: 'None', secure: true });
    return RefreshState.SuccessfullyRefreshedTokens;
  } catch (e) {
    // Error occured within the refresh and could be a race condition
    // Try the refresh check once more
    return RefreshState.ErrorRefreshingTokens;
  }
};

const isAuthenticated = () => {
  const status = getAuthenticationStatus();
  switch (status) {
    case AuthenticationState.NoTokens:
    case AuthenticationState.ExpiredRefreshToken:
      return false;
    default:
      return true;
  }
};

const getAxiosInterceptorConfig = async (config: any) => {
  let retryCount = 0;
  const updatedConfig = config;
  const status = getAuthenticationStatus();
  switch (status) {
    case AuthenticationState.ExpiredRefreshToken:
    case AuthenticationState.NoTokens:
    case AuthenticationState.InvalidTokens:
      // Invalid tokens let the request fail
      return config;
    case AuthenticationState.ExpiredAccessToken: {
      // Handle Refresh
      if (Cookies.get('isRefreshing') === 'true') {
        return config;
      }
      Cookies.set('isRefreshing', 'true');
      let refreshState = await refreshTokens();

      Cookies.remove('isRefreshing');
      if (refreshState === RefreshState.ErrorRefreshingTokens) {
        // If after retryCount > MAX_RETRY_ATTEMPT and still Error
        // Fail gracefully.
        return config;
      }
      const token = Cookies.get('access');
      updatedConfig.headers.Authorization = `Bearer ${token}`;
      return updatedConfig;
    }
    default: {
      // Valid tokens
      const token = Cookies.get('access');
      updatedConfig.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  }
};

const sendToast = (text: any, args?: ToastOptions) => {
  const defaultArgs: ToastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };
  toast(text, { ...defaultArgs, ...args });
};

const getUTCDate = (date: Date) => {
  const dtYear = date.getFullYear();
  const dtMonth = date.getMonth();
  const dtDate = date.getDate();
  const ms = Date.UTC(dtYear, dtMonth, dtDate);

  return ms;
}

const convertUTCToLocalDateObject = (dateInt: any) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
      return date;
  } else {
      const msInMinute = 60000;
      const timezoneTimestamp = date.getTime() + date.getTimezoneOffset() * msInMinute;
      date.setTime(timezoneTimestamp)
      return date;
  }
}

const checkRequiredFields = (config: any[], data: any) => {
  let invalid = false;
  let requiredLabelName = '';
  const requiredFields = config.filter((field: any) => field.required);
  if (requiredFields.length > 0) {
    requiredFields.forEach((field) => {
      if (!data[field.keyName]) {
        invalid = true;
        requiredLabelName = field.label;
      }
    })
  }
  return {
    invalid,
    requiredLabelName,
  }
}

const AuthenticationTypes = {
  NotAuthenticated: 'Not Authenticated',
  FetchMagicLink: 'Fetch magic link',
  AlreadyAuthenticated: 'Already Authenticated',
};

const AuthCheck = (magicUuid: string | null, authenticated: boolean) => {
  if (authenticated) {
    return AuthenticationTypes.AlreadyAuthenticated;
  }
  if (magicUuid) {
    return AuthenticationTypes.FetchMagicLink;
  }

  return AuthenticationTypes.NotAuthenticated;
};

export {
  sendToast,
  isAuthenticated,
  getUTCDate,
  convertUTCToLocalDateObject,
  checkRequiredFields,
  AuthCheck,
  AuthenticationTypes,
  getAuthenticationStatus,
  RefreshState,
  refreshTokens,
  AuthenticationState,
  getAxiosInterceptorConfig,
}
