import { createSlice } from '@reduxjs/toolkit';
import { Widgets } from '../../components/types/Widgets';

const initialState = {
  loading: false,
  selectedWidget: null as Widgets | null,
}

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    selectWidget(state, { payload }) {
      state.selectedWidget = payload;
    }
  },
  // extraReducers: builder = {}
})

export const { selectWidget } = widgetSlice.actions;

export default widgetSlice.reducer;
