import { createSlice } from "@reduxjs/toolkit";
import { DealResults, Deals } from "../../components/types/Deals";
import { RequestError } from "../Conversation/actions";
import { fetchDealsFromCustomerId } from "./actions";

const initialState = {
  loading: false,
  deals: null as Deals | null,
  error: null as any,
  selectedDeal: null as DealResults | null,
  openDealModal: false,
}

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    selectDeal(state, { payload }) {
      state.selectedDeal = payload;
    },
    openDealModal(state, { payload }) {
      state.openDealModal = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDealsFromCustomerId.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchDealsFromCustomerId.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.deals = payload as Deals;
      }
    });
    builder.addCase(fetchDealsFromCustomerId.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
  }
})

export const { selectDeal, openDealModal } = dealsSlice.actions;

export default dealsSlice.reducer;