import { createAsyncThunk } from "@reduxjs/toolkit";
import { Messages } from "../../components/Dashboard/Conversations/types/Message";
import Axios from '../../utils/Axios';
import { RequestError } from "../Conversation/actions";

export type MessagesProps = {
  page: number | null,
  id: string,
}

export type SendMessageProps = {
  message: string,
  send_at?: string | null,
  id: string,
}

export type SendMessageResponse = {
  success: boolean,
}

export const fetchMessagesFromConversationId = createAsyncThunk<Messages | RequestError, MessagesProps>(
  'messages/fetchMessagesFromConversationId',
  async (props) => {
    try {
      const response = await Axios.get(`/communications/conversations/${props.id}/messages/${props.page ? `?page=${props.page}` : ''}`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export const sendMessage = createAsyncThunk<SendMessageResponse | RequestError, SendMessageProps>(
  'messages/sendMessage',
  async (props) => {
    try {
      await Axios.post(`/communications/conversations/${props.id}/message/`, {
        message: props.message,
        send_at: props.send_at,
      })
      return { success: true };
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export const deleteMessage = createAsyncThunk<any, number|string>(
  'messages/deleteMessage',
  async (id) => {
    try {
      await Axios.delete(`/communications/conversations/message/${id}/delete/`)
      return {
        success: true,
      }
    } catch (err) {
      return { hasError: true, error: "There was an error deleting the message" }
    }
  }
)