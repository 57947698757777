import { createSlice } from '@reduxjs/toolkit';
import { SettingSectionTypes } from '../../components/Settings/SettingSectionTypes';
import { NavViewTypes } from '../../components/SidebarNav/NavViewTypes';

const initialState = {
    selectedView: NavViewTypes.Conversation,
    selectedSettingsSection: SettingSectionTypes.Account,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setSelectedView(state, { payload }) {
            state.selectedView = payload;
        },
        setSelectedSettingsSection(state, { payload }) {
            state.selectedSettingsSection = payload;
        }
    },
})

export const {  setSelectedView, setSelectedSettingsSection  } = navigationSlice.actions;

export default navigationSlice.reducer;