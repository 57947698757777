// import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { getAxiosInterceptorConfig } from './helpers';

const baseURL: string | undefined = process.env.GATSBY_API_URL;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  async (config): Promise<any> => {
    const newConfig = await getAxiosInterceptorConfig(config);
    return newConfig;
  },
);

export default instance;
