import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import conversationReducer from './Conversation/reducer';
import messagesReducer from './Message/reducer';
import widgetReducer from './Widget/reducer';
import callsReducer from './Calls/reducer';
import dealsReducer from './Deals/reducer';
import updateConnectionReducer from './UpdateConnection/reducer';
import smsHistoryReducer from './SMSHistory/reducer';
import contactsReducer from './Contacts/reducer';
import navigationReducer from './Navigation/reducer';
// import magicLinksReducer from './MagicLinks/reducer';
// import communicationsReducer from './Communications/reducer';
// import surveysReducer from './Surveys/reducer';
// import dataSenderReducer from './DataSender/reducer';

const rootReducer = combineReducers({
  conversation: conversationReducer,
  messages: messagesReducer,
  widget: widgetReducer,
  calls: callsReducer,
  deals: dealsReducer,
  updateConnection: updateConnectionReducer,
  smsHistory: smsHistoryReducer,
  contacts: contactsReducer,
  navigation: navigationReducer,
  // magicLinks: magicLinksReducer,
  // communications: communicationsReducer,
  // surveys: surveysReducer,
  // dataSender: dataSenderReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;