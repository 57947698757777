import { createSlice } from '@reduxjs/toolkit';
import { Conversation } from '../../components/Dashboard/Conversations/types/Conversation';
import { Customer } from '../../components/Dashboard/Conversations/types/Customer';
import { MessageThread } from '../../components/Dashboard/Conversations/types/MessageThread';
import { TabTypes } from '../../components/Dashboard/Conversations/types/TabTypes';
import { sendToast } from '../../utils/helpers';
import { archiveConversation, assignConversationToTeam, assignConversationToUser, fetchConversations, filterBySearch, RequestError, updateCustomer } from './actions';


const initialState = {
  loading: false,
  selectedMessageThread: null as MessageThread | null,
  conversations: null as Conversation | null,
  messageThread: [] as MessageThread[],
  error: null as any,
  selectedTab: TabTypes.Chat,
  successfullyAssigned: false,
}

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setSelectedTab(state, { payload }) {
      state.selectedTab = payload;
    },
    setSelectedMessageThread(state, { payload }) {
      state.selectedMessageThread = payload;
      state.selectedTab = TabTypes.Chat;
    },
    updateConversations(state, { payload }) {
      state.conversations = payload;
    },
    setConversationError(state, { payload }) {
      state.error = payload
    },
    resetSuccessfullyAssigned(state) {
      state.successfullyAssigned = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchConversations.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchConversations.fulfilled, (state, { meta, payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        if (state.conversations && meta.arg !== 1) {
          const conversationResults = state.conversations.results.concat((payload as Conversation).results);
          state.conversations = payload as Conversation;
          state.conversations.results = conversationResults;
        } else {
          state.conversations = payload as Conversation;
        }
      }
    });
    builder.addCase(fetchConversations.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });

    builder.addCase(archiveConversation.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(archiveConversation.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.selectedMessageThread = payload as MessageThread;
      }
    });
    builder.addCase(archiveConversation.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });

    builder.addCase(assignConversationToTeam.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(assignConversationToTeam.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.selectedMessageThread = payload as MessageThread;
        state.successfullyAssigned = true;
        sendToast('Successfully assigned to team', { type: 'success'})
      }
    });
    builder.addCase(assignConversationToTeam.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });

    builder.addCase(assignConversationToUser.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(assignConversationToUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.selectedMessageThread = payload as MessageThread;
        state.successfullyAssigned = true;
        sendToast('Successfully assigned user', { type: 'success'})
      }
      
    });
    builder.addCase(assignConversationToUser.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
    // Updating Contact
    builder.addCase(updateCustomer.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        if (state.selectedMessageThread) {
          state.selectedMessageThread.customers = [payload as Customer];
        }
        sendToast('Successfully updated user', { type: 'success'})
      }
      
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });

    // Search
    builder.addCase(filterBySearch.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(filterBySearch.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.conversations = payload as Conversation;
      }
    });
    builder.addCase(filterBySearch.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again' }; // TODO: add errors
    });
  }
})

export const { updateConversations, setSelectedMessageThread, setConversationError, setSelectedTab, resetSuccessfullyAssigned } = conversationSlice.actions;

export default conversationSlice.reducer;
