import { createAsyncThunk } from "@reduxjs/toolkit";
import { Conversation } from "../../components/Dashboard/Conversations/types/Conversation";
import { Customer } from "../../components/Dashboard/Conversations/types/Customer";
import { MessageThread } from "../../components/Dashboard/Conversations/types/MessageThread";
import Axios from '../../utils/Axios';
import { updateConversations } from "./reducer";

export type RequestError = {
  hasError: boolean,
  error: any,
}

export const fetchConversations = createAsyncThunk<Conversation | RequestError, number | null>(
  'conversation/fetchConversations',
  async (page = null) => {
    try {
      const response = await Axios.get(`/communications/conversations/${page ? `?page=${page}` : ''}`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export type ArchiveProps = {
  id: string,
}

export const archiveConversation = createAsyncThunk<MessageThread | RequestError, ArchiveProps>(
  'conversation/archiveConversation',
  async (props) => {
    try {
      const response = await Axios.patch(`/communications/conversations/${props.id}/`, {
       archive: true,
      })

      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export type AssignToTeamProps = {
  id: string,
  teamId: number,
}

export const assignConversationToTeam = createAsyncThunk<MessageThread | RequestError, AssignToTeamProps>(
  'conversation/assignConversationToTeam',
  async (props) => {
    try {
      const response = await Axios.patch(`/communications/conversations/${props.id}/`, {
        team: props.teamId,
      })
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export type AssignToUserProps = {
  id: string,
  userId: number, 
}

export const assignConversationToUser = createAsyncThunk<MessageThread | RequestError, AssignToUserProps>(
  'conversation/assignConversationToUser',
  async (props) => {
    try {
      const response = await Axios.patch(`/communications/conversations/${props.id}/`, {
        assigned_to_id: props.userId,
      })
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export type CustomerProps = {
  customer: Customer,
}

export const updateCustomer = createAsyncThunk<Customer | RequestError, CustomerProps>(
  'conversations/updateCustomerInfo',
  async (props) => {
    try {
      const { customer } = props;
      const id = customer.id;
      const data = customer as any; //
      delete data.id;
      const response = await Axios.patch(`/contacts/customer/${id}/`, {
        ...data,
      })
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)

export type SearchProps = {
   searchValue: string,
}

export const filterBySearch = createAsyncThunk<Conversation | RequestError, SearchProps>(
  'conversations/search',
  async (props) => {
    try {
      const { searchValue } = props;
      const response = await Axios.get(`/communications/conversations/?search=${searchValue}`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)