import { createAsyncThunk } from "@reduxjs/toolkit";
import { SMSHistory } from "../../components/types/SMSHistory";
import Axios from '../../utils/Axios';
import { RequestError } from "../Conversation/actions";

export type FetchProps = {
  page: number | null,
  id: string,
}

export const fetchSMSHistoryFromContactId = createAsyncThunk<SMSHistory | RequestError, FetchProps>(
  'smsHistory/fetchSMSHistoryFromContactId',
  async (props) => {
    try {
      const response = await Axios.get(`/communications/conversations/contact/${props.id}/${props.page ? `?page=${props.page}` : ''}`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)