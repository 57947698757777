import { createAsyncThunk } from "@reduxjs/toolkit";
import { Deals } from "../../components/types/Deals";
import Axios from '../../utils/Axios';
import { RequestError } from "../Conversation/actions";

export type DealsRequestProps = {
  vid: string,
}

export const fetchDealsFromCustomerId = createAsyncThunk<Deals | RequestError, DealsRequestProps>(
  'deals/fetchDealsFromCustomerId',
  async (props) => {
    try {
      const response = await Axios.get(`/contacts/deals/${props.vid}/`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)