import { createAsyncThunk } from "@reduxjs/toolkit";
import { GroupResults, UserResults } from "../../components/types/Contacts";
import Axios from '../../utils/Axios';
import { RequestError } from "../Conversation/actions";

export const fetchTeams = createAsyncThunk<GroupResults | RequestError>(
  'contacts/fetchTeams',
  async () => {
    try {
      const response = await Axios.get(`/contacts/groups/`);
      return response.data;
    } catch(err) {
      return { hasError: true, error: (err as any).response.data };
    }
  }
)

export const fetchUsers = createAsyncThunk<UserResults | RequestError>(
  'contacts/fetchUsers',
  async () => {
    try {
      const response = await Axios.get(`/contacts/users/`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data };
    }
  }
)