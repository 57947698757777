import { createSlice } from "@reduxjs/toolkit";
import { CallResults, Calls } from "../../components/types/Calls";
import { RequestError } from "../Conversation/actions";
import { fetchCallsFromCustomerId } from "./actions";

const initialState = {
  loading: false,
  callHistory: null as Calls | null,
  error: null as any,
}

const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCallsFromCustomerId.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCallsFromCustomerId.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.callHistory = payload as Calls;
      }
    });
    builder.addCase(fetchCallsFromCustomerId.rejected, (state) => {
      state.loading = false;
      state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
  }
})

export default callsSlice.reducer;