import { createSlice } from '@reduxjs/toolkit';
import { SMSHistory } from '../../components/types/SMSHistory';
import { RequestError } from '../Conversation/actions';
import { fetchSMSHistoryFromContactId } from './actions';


const initialState = {
  loading: false,
  smsHistory: null as null | SMSHistory,
  error: null as RequestError | null,
}

const smsHistorySlice = createSlice({
  name: 'smsHistory',
  initialState,
  reducers: {
    // updateMessages(state, { payload }) {
    //   if (state.messages) {
    //     const messageThreads = state.messages.results;
    //     messageThreads.unshift(payload)
    //     state.messages = { ...state.messages, results: messageThreads }
    //   }
    // }
  },
  extraReducers: builder => {
    builder.addCase(fetchSMSHistoryFromContactId.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSMSHistoryFromContactId.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as RequestError).hasError) {
        state.error = (payload as RequestError).error
      } else {
        state.smsHistory = payload as SMSHistory;
      }
    });
    builder.addCase(fetchSMSHistoryFromContactId.rejected, (state) => {
      state.loading = false;
      // state.error = { message: 'There was an internal error fetching conversations. Please try again'}; // TODO: add errors
    });
  }
})

// export const { updateMessages } = smsHistorySlice.actions;

export default smsHistorySlice.reducer;
