import { createAsyncThunk } from "@reduxjs/toolkit";
import { Calls } from "../../components/types/Calls";
import Axios from '../../utils/Axios';
import { RequestError } from "../Conversation/actions";

export type CallsRequestProps = {
  vid: string,
}

export const fetchCallsFromCustomerId = createAsyncThunk<Calls | RequestError, CallsRequestProps>(
  'calls/fetchCallsFromCustomerId',
  async (props) => {
    try {
      const response = await Axios.get(`/communications/calls/${props.vid}/`)
      return response.data;
    } catch (err) {
      return { hasError: true, error: (err as any).response.data }
    }
  }
)